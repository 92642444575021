/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import axios from "axios";
import LCFQheader from "../../LCFQheader";
import Tzry from "../../../common/fw/Tzry";
import FwTzrySelect from "../../../common/fw/fwTzrySelect";
import { RxUtil } from "@/assets/util.js";
import { timesFnt, format, formatHMT } from "@/assets/app.js";
export default {
  name: "lcfqYysp",
  components: {
    LCFQheader,
    Tzry,
    FwTzrySelect
    // MyTableInfo,
  },

  data() {
    return {
      oneft: "/index",
      headTit: "新建申请",
      tit: "用印审批单",
      //userSelect
      single: false,
      allowSelect: true,
      showUserDialog: false,
      showDateYy: false,
      minDate: new Date(2020, 0, 1),
      currentDate: new Date(),
      showListpage: false,
      showRyDelect: false,
      showGinfo: false,
      userID: "",
      solId: "",
      actDefId: "",
      boDefId: "",
      formKey: "",
      checList: [],
      //输入框
      rysList: [],
      yxryList: [],
      fjsc: [],
      userid: "",
      data: {
        bh: "",
        yyr: "",
        fj: []
      }
    };
  },
  created() {
    this.cgORxj();
    // this.initBySolInstId();
  },

  methods: {
    initBySolInstId() {
      this.data.yyr = RxUtil.getCache("userName");
      let solId = this.$route.params.solId;
      var url = _baseUrl + "/mobile/bpm/startForm.do?solId=" + solId;
      this.$ajax.post(url).then(response => {
        this.solId = response.data.bpmSolution.solId;
        this.actDefId = response.data.bpmSolution.actDefId;
        this.boDefId = response.data.formModels[0].boDefId;
        this.formKey = response.data.formModels[0].formKey;
        this.data.bh = response.data.formModels[0].jsonData.bh;
      });
      let checUrl = _baseUrl + "/sys/db/sysSqlCustomQuery/queryForJson_hqqyyz.do?_=1641279752489";
      this.$ajax.post(checUrl).then(response => {
        this.checList = response.data.data;
      });
    },
    async cgORxj() {
      let solId = this.$route.params.solId;
      let instId = this.$route.params.instId;
      if (instId != 0) {
        this.headTit = "我的草稿";
        this.oneft = "/myDrafts";
        var url = _baseUrl + "/mobile/bpm/startForm.do?solId=" + solId + "&instId=" + instId;
        this.$ajax.post(url).then(res => {
          this.solId = res.data.bpmSolution.solId;
          this.actDefId = res.data.bpmSolution.actDefId;
          this.boDefId = res.data.formModels[0].boDefId;
          this.formKey = res.data.formModels[0].formKey;
          this.data = res.data.formModels[0].jsonData;
          this.data.yzty = [];
        });
      } else {
        this.initBySolInstId();
      }
    },
    //选人
    selectUserDialog(vm) {
      this.showUserDialog = true;
      this.$refs.sysUser.search();
      // 设置选择用户。
      this.$refs.sysUser.setValue("");
      //设置选中的rx-box 控件，用于传递数据。
      this.nodeUserVm = vm;
    },
    selectUser(data) {
      var list = [];
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        var o = {};
        o.id = obj.userId;
        o.text = obj.fullname;
        list.push(o);
      }
      var json = JSON.stringify(list);
      console.log(json);
      this.nodeUserVm.setValue(json);
      this.showUserDialog = false;
    },
    onConfirmYy(timestamp) {
      this.data.yyrq = format(timestamp);
      this.showDateYy = false;
    },
    showList() {
      this.showListpage = true;
    },
    //选择通知人员
    tzryFun(list) {
      let ryListName = [];
      let userid = [];
      this.rysList = list;
      this.rysList.map(item => {
        ryListName.push(item.FULLNAME_);
        userid.push(item.USER_ID_);
      });
      this.data.tzr = ryListName.join(",");
      this.userid = userid.join(",");
    },
    ryList(list) {
      this.tzryFun(list);
      this.showListpage = false;
    },
    delectRy() {
      console.log(this.rysList);
      this.yxryList = this.rysList;
      this.showRyDelect = true;
    },
    delectAfter(lists) {
      this.tzryFun(lists);
      this.showRyDelect = false;
    },
    //上传附件
    beforeRead(file) {
      if (this.data.fj.length > 0) {
        let fjStr = this.onFile();
        if (fjStr.indexOf(file.name) == -1) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    afterRead(file) {
      let formData = new FormData();
      formData.append("from", "SELF");
      formData.append("types", "");
      formData.append("sysTreeId", "");
      formData.append("id", "WU_FILE_0");
      formData.append("name", file.file.name);
      formData.append("type", file.file.type);
      formData.append("lastModifiedDate", file.file.lastModifiedDate);
      formData.append("size", file.file.size);
      formData.append("file", file.file);
      var fjObj;
      let formDataUrl = _baseUrl + "/sys/core/file/upload.do";
      this.$ajax.post(formDataUrl, formData).then(res => {
        fjObj = {};
        fjObj.fileId = res.data.data[0].fileId;
        fjObj.fileName = res.data.data[0].fileName;
        fjObj.totalBytes = res.data.data[0].totalBytes;
        this.fjsc.push(fjObj);
      });
    },
    beforeDelete(file) {
      if (this.data.fj.length > 1) {
        for (let i = 0; i < this.fjsc.length; i++) {
          if (file.file.name == this.fj[i].fileName) {
            this.fj.splice(i, 1);
          }
        }
        return true;
      } else {
        return true;
      }
    },
    onFile() {
      let fjObj = {};
      let fj = [];
      this.data.fj.map(item => {
        fjObj = {};
        fjObj.fileId = item.file.lastModified;
        fjObj.fileName = item.file.name;
        fjObj.totalBytes = item.file.size;
        fj.push(fjObj);
      });
      return JSON.stringify(fj);
    },
    datas() {
      let fj = JSON.stringify(this.fjsc);
      let yzmcList = this.data.yzmc;
      let yzmc = yzmcList.join(",");
      console.log(yzmc);
      var jsonDataObj = {
        bos: [{
          boDefId: this.boDefId,
          formKey: this.formKey,
          data: {
            yhid: this.userid,
            bh: this.data.bh,
            yyr: this.data.yyr,
            yyrq: formatHMT(this.data.yyrq),
            yzmc: yzmc,
            tzr: this.data.tzr,
            wj: "",
            fj: fj,
            gyfs: this.data.gyfs,
            mfys: this.data.mfys,
            // yzpd:
            gy: this.data.gy,
            bz: this.data.bz,
            tzr: this.data.tzr,
            yzmc_name: yzmc
          }
        }]
      };
      var jsonData = JSON.stringify(jsonDataObj);
      var params = new FormData();
      params.append("solId", this.solId);
      params.append("bpmInstId", "");
      params.append("jsonData", jsonData);
      return params;
    },
    onSubmit() {
      // this.datas();
      var params = this.datas();
      var url = _baseUrl + "/bpm/core/bpmInst/startProcess.do";
      this.$dialog.loading.open('正在启动流程,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("启动成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("启动失败！" + data.data, "1800");
        }
      });
    },
    onPreservation() {
      var params = this.datas();
      var url = _baseUrl + "/bpm/core/bpmInst/saveDraft.do";
      this.$dialog.loading.open('正在保存草稿,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("保存成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("保存失败！" + data.data, "1800");
        }
      });
    },
    onLct() {
      this.$router.push({
        name: "procImage",
        params: {
          id: this.actDefId,
          type: 'start'
        }
      });
    }
  }
};